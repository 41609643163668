.modalHeader{
    border: none;
}
.modalHeader .close{
    /* background-color: #B5322A; */
    color: #B5322A;
    font-weight: 900;
    font-size: 32px;
    border-radius: 15px;
    outline: none;
    /* margin-left: 1px; */
}
.ModalImageHolder{
    min-height: 40vh;
    position: relative;

}
.modalImage{
    position: absolute;
    left : 25%;
    top : 25%;
    /* transform: translate(-50%, -50%); */
}
.modalFooter{
    border: none;
}
.modalButton{
    background-color: #B5322A;
    outline: none;
    padding: 15px;
    width : 40%;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    margin-bottom: 5%;
}
.modalButton :focus {
    background-color: #B5322A;
    outline: none;
    padding: 15px;
    width : 40%;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    margin-bottom: 5%;
}