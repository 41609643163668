//modal
@keyframes modalFade {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.LoginModal{
    padding: 10% 12%;
    background-color: white;
    height: 100vh;
    overflow: auto;


    .heading{
        font-size: 26px;
    }
    .Text{
        font-size: 14px;
    }
    .Login-input{
        background: #f9f4f3;
        font-size: 16px;
        font-family: poppins;
        font-weight: 300;
        height: 60px;
        width: 100%;
        border-radius: 10px;
        padding-left: 15px;
        margin-top:35px;
        margin-bottom: 5px;
        border: none;

    }
    .Login-input:hover{
        background: white;
        border: 1px solid #B5322A;

    }
    .Login-input:focus{
        outline: none;
        border: 1px solid #B5322A;
    }
    .arrowBtn{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #B5322A;
        border: none;
        color: white;
    }
    .Arrowfa{
        font-size: 20px;
    }
    .ForgetText{
        color: #A8A8A8;
        font-size: 14px;
        margin-top: 21px;

    }
    .ForgetTextPrimary{
        color: #B5322A;
        font-size: 15px;

    }
    .ForgetTextPrimary:hover{
        cursor: pointer;
        opacity: 0.8;
        
    }
    .LoginBtn{
        height: 60px;
        width: 200px;
        font-size: 16px;
        font-family: poppins;
        font-weight: 500;
        background: #B5322A;
        border: none;
        border-radius: 10px;
        color: white;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    // .LoginBtn:hover{
    //     background-color: #f8ebba;
    //     color: #B5322A;
    // }
    // .LoginBtn:focus{
    //     outline: none;
    //     border: 2px solid #B5322A;
    // }
}
.RegisterModal{
    padding: 4%;
    background-color: white;
    height: 100vh;
    overflow: auto;


    .heading{
        font-size: 26px;
    }
    .Text{
        font-size: 14px;
    }
    .Login-input{
        background: #f9f4f3;
        font-size: 15px;
        font-family: poppins;
        font-weight: 300;
        height: 50px;
        width: 100%;
        border-radius: 5px;
        padding-left: 15px;
        margin-top: 27px;
        margin-bottom: 5px;
        border: none;

    }
    .Login-input:hover{
        background: white;
        border: 1px solid #B5322A;

    }
    .Login-input:focus{
        outline: none;
        border: 1px solid #B5322A;
    }
    .arrowBtn{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #B5322A;
        border: none;
        color: white;
    }
    .Arrowfa{
        font-size: 20px;
    }
    .ForgetText{
        color: #A8A8A8;
        font-size: 14px;
    }
    .ForgetTextPrimary{
        color: #B5322A;
        font-size: 15px;

    }
    .ForgetTextPrimary:hover{
        cursor: pointer;
        opacity: 0.8;
        
    }
    .LoginBtn{
        height: 50px;
        width: 100%;
        font-size: 16px;
        font-family: poppins;
        font-weight: 500;
        background: #B5322A;
        border: none;
        border-radius: 10px;
        color: white;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    // .LoginBtn:hover{
    //     background-color: #f8ebba;
    //     color: #B5322A;
    // }
    // .LoginBtn:focus{
    //     outline: none;
    //     border: 2px solid #B5322A;
    // }
    .verifybtn {
        position: absolute;
        height: 50px;
        border-radius: 4px;
        background: #B5322A;
        color: white;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
        border: none;
        font-family: 'Poppins';
        font-weight: 300;
        right: 2px;
        margin-top: 26px;
    }
    .verifybtn:hover{
        background-color: #f8ebba;
        color: #B5322A;
    }
    .verifybtn:focus{
        outline: none;
        border: 2px solid #B5322A;
    }
}

.modal-open .modal-dialog {
    right: -15px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.sideCart {

    .modal-content {
        height: 100vh;
        width: 50%;
        position: fixed;
        top: 0px;
        right: 0px;
        height: 100vh;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        background: #FAFBFE;
    }
}
.ResgisterSideCart{
    .modal-content {
        height: 100vh;
        width: 75%;
        position: fixed;
        top: 0px;
        right: 0px;
        height: 100vh;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        background: #FAFBFE;
    }
}

.checkBox{
    height: 16px;
    width: 16px;
    margin-right: 10px;
}
.checkBoxLabel{
    font-size: 14px;
    margin-left: 12px;
    position: absolute;
    margin-top: 3px;
    color: #A8A8A8;
}
//Modal End


.login-container {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
}

.Signup-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.Login_internal_container {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    background: white;
    width: 93%;
    padding: 20px;
    border-radius: 31px;
    margin-bottom: 26px;
    box-shadow: 0px 0px 4px 2px #d1c5c5
}

.PhoneInputInput {
    border: none;
    background: none;
}

.PhoneInputInput:focus {
    outline: none;

}

.PhoneInputCountry {
    margin-left: 15px;
}

.getCodeBtn {
    position: absolute;
    height: 45px;
    border-radius: 4px;
    background: #519571;
    color: white;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    font-family: 'Poppins';
    font-weight: 300;
    right: 2px;
    margin-top: 14px;


}
.otpp{
    display: block;
    width: 100% !important;
    margin-right: 40px;
}
.otpInput {
    height: 44px;
    margin-top: 6px;
    width: 43px !important;
    border-radius: 6px;
    border: 1px solid #8A8A8A !important;
    font-size: 20px;
    padding-left: 0px;
    font-family: 'Poppins';
    font-weight: 600;
    color: #BB423B;
    margin-right: 15px;

}

.otpInput:focus {
    border: 1px solid #BB423B;
    outline: none;
}

.otpInput:hover {
    border: 1px solid #BB423B;
}

#partitioned {
    padding-left: 10px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 230px;
    min-width: 228px;
    font-size: 17px;
    height: 44px;
    margin-top: 6px;
}

#partitioned:focus {
    outline: none;
}

#divInner {
    left: 0;
    position: sticky;
}

#divOuter {
    width: 190px;
    overflow: hidden;
}

.logincard {
    -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.15);
    padding: 45px;
    border-radius: 26px;

}

.sing-horizontal-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.sign_heading {
    font-size: 33px;
}

.sign_text {
    color: #A6ABAB;
    font-size: 13px;
}

.sign-form-text {
    font-size: 13px;
    color: #606060;
    margin-top: 20px !important;
    margin-bottom: 5px;

}

button[disabled],
html input[disabled] {
    cursor: not-allowed;
    background-color: #9a9a9a;

}

button[disabled],
html input[disabled]:hover {}

input[disabled],
html input[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: #9a9a9a;
}


.sign_verification_btn:hover {
    opacity: 0.9;
}

.forget-form-text {
    font-size: 13px;
    color: #606060;

}

.forget-form-text:hover {
    cursor: pointer;
    opacity: 0.8;
}

.sign-input {
    border: 1px solid #003459;
    height: 44px;
    border-radius: 7px;
    font-size: 15px;
    font-family: poppins;
    font-weight: 400;
    margin-top: 6px;

}

.emailsenttext {
    position: absolute;
    color: green;
    font-size: 12px;
    line-height: 1.2;
    margin: 0px;
    margin-top: 5px;
}

.verfication_code_input {
    letter-spacing: 20px;
    padding-left: 25px;
}

.mt_4px {
    margin-top: 4px;
}

.sign-input:focus {
    outline: none;
    border: 2px solid #BB423B;
}

.sign_verification_btn {
    background-color: #9a9a9a;
    color: white;
    font-size: 13px;
    border: none;
    height: 44px;
    border-radius: 7px;
    margin-top: 50px;

}

.sign_verification_btn:hover {
    opacity: 0.8;
    background-color: #BB423B;


}

.sign_verification_btn:focus {
    outline: none;
    opacity: 1;
    background-color: #BB423B;

    border: 2px solid #BB423B;

}

.sign_reading_btn {
    background-color: #9a9a9a;
    color: white;
    font-size: 13px;
    border: none;
    height: 44px;
    border-radius: 7px;

}

.sign_reading_btn:hover {
    background-color: #BB423B;
    opacity: 0.7;
}

.sign_reading_btn:focus {
    background-color: #BB423B;
    opacity: 1;
    border: 2px solid #BB423B;
    outline: none;


}

.signin_container {


    .form-group {
        display: block;
        margin-bottom: 15px;
    }

    .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .form-group label {
        position: relative;
        cursor: pointer;
    }

    .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 7px;
        // border-radius: 5px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
    }


    .form-group input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 1px;
        height: 0px;
        border: 7px solid #BB423B;
        border-radius: 0px;

    }

    .checkbox_label {
        font-size: 10px;
        font-family: 'Poppins';

        color: #BB423B;
    }

}

.register-card1Login {
    box-shadow: 0px 0px 10px 0px #b7b3;
    width: 100%;
    border-radius: 15px;
    color: white;
    width: 93%;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: auto;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 23px;
    margin-top: 0px !important;
}

.register-card1 {
    box-shadow: 0px 0px 10px 0px #b7b3;
    width: 100%;
    border-radius: 15px;
    color: white;
    width: 93%;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: auto;
    border-radius: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 14px !important;
}

.publshercard {
    background-color: #517A95;
}

.publshercardbtn {
    color: #517A95;

}

.bookstorecard {
    background-color: #BB423B;
}

.bookstorecardbtn {
    color: #BB423B;
}

.authorcardd {
    background-color: #EBB747;
}

.authorcarddbtn {
    color: #EBB747;
}

.storycardd {
    background-color: #2A2A2A;
}

.storycarddbtn {
    color: #2A2A2A;
}

.signdiscount-img-heading {
    font-size: 18px;
    color: #fff;
}

.signdiscount_img_text {
    font-size: 12px;
}

.sign_register_button {
    background: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
}

.sign_register_button:hover {
    opacity: 0.8;

}

.sign-discount-img {
    margin-top: 40px;
    margin-bottom: 15px;
}

@media (max-width: 1200px) {
    .sign_verification_btn {
        // margin: 0px;
    }

    .invalidveifcationerror {
        color: red;
        position: absolute;
        text-align: right;
        margin-top: 0px;
        font-family: poppins;
        font-weight: 400;
        left: 30px;
        font-size: 11px;
        right: 28px
    }
}

@media (max-width: 990px) {
    .sign_verification_btn {
        // margin: 0px;
    }

    .register-card1 {
        // height: 300px;
        width: 100%;
    }
    .sideCart {

        .modal-content {
            height: 100vh;
            width: 100%;
         
    }
   
    .LoginModal {
        padding: 10% 10%;
        background-color: white;
    }
}
.ResgisterSideCart {
.modal-content {
    width: 100%;
    }
    .verifybtn{
        margin-top: 0px;
    }
}
}

@media (max-width: 767px) {
    .sign_verification_btn {
        // margin: 0px;
        margin-top: 25px;
    }

    .register-card1 {
        // height: 300px;
        width: 100%;
        margin-top: 38px;
        margin-bottom: 20px;
    }
}

.nav_background {
    background-color: #fff;
}

.invaliderror {
    color: red;
    position: absolute;
    text-align: right;
    margin-top: 0px;
    font-family: poppins;
    font-weight: 400;
    left: 30px;
    font-size: 11px;
    right: 28px;

}

.invalidveifcationerror {
    color: red;
    position: absolute;
    text-align: right;
    margin-top: 0px;
    font-family: poppins;
    font-weight: 400;
    left: 30px;
    font-size: 11px;
    left: 85px;

}

.invaliderror1 {
    color: red;
    position: absolute;
    text-align: right;
    margin-top: -1px;
    font-family: poppins;
    font-weight: 400;
    right: 59px;
    font-size: 11px;

}