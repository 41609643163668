.OrderStatusContainer{
    .heading{
        font-size: 33px;
    }
    .text{
        font-size: 14px;
        color: #A6ABAB;
    }
    .bookTitle{
        height: 135px        ;
        
    }
    .center{
        margin-left: auto;
        margin-right: auto;
    }
    .tablebtn{
        height: 41px;
    width: 131px;
    background: #BB423B;
    color: white;
    font-family: 'Poppins';
    font-weight: 500;
    border: none;
    border-radius: 8px;
    }
    .tablebtn:hover{
        background: #A45752;

    }
    .tablebtn:focus{
        background: #61100C;

    }

}