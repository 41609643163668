.error__main {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

/* .error__main div{
    width: 50%;
} */
.error__rightMost {
    width: 40vw;
    /* border : 2px solid green; */
}

.error__leftMost {
    width: 60vw;
    /* border : 2px solid green; */
    position: relative;
}

.error__rightMost img {
    /* border : 1px solid red; */
    margin: 0px;
    /* padding-right: -30px; */
}

.error__leftMost .error__inner {
    /* border: 1px solid red; */
    /* width : 50%; */
    position: absolute;
    left: 10%;
    top: 10%;
    /* transform: translate(-25%,-25%); */
}

.stayConnected {
    width: 25%;
    background-color: #fac85b5a;
    padding: 5px;
    color: black;
    font-weight: 600;
    font-size: 100%;
    margin-left: 0;
}

.error__text h1 {
    color: black;
    font-size: 52px;
}

.error__text h1 span {
    color: #B5322A;
}

.error__divider {
    border: 2px solid #517A95;
    /* border: #517A95; */
    border-radius: 15px;
    background-color: #517A95;
    width: 10%;
    margin: 0;
}

.error__text p {
    font-size: 16px;
    font-weight: 500;
}

.error__text .errorpara__des {
    font-weight: 100;
}



/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .error__leftMost .error__inner {
        /* border: 1px solid red; */
        /* width : 50%; */
        position: absolute;
        left: 0%;
        /* transform: translate(-25%,-25%); */
    }
    .error__leftMost {
        width: 70vw;
        /* border : 2px solid green; */
        position: relative;
    }
    .error__rightMost{
        width: 30vw;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .error__leftMost .error__inner {
        /* border: 1px solid red; */
        /* width : 50%; */
        position: absolute;
        left: 10%;
        top: 5%;
        /* transform: translate(-25%,-25%); */
    }
    .error__leftMost {
        width: 70vw;
        /* border : 2px solid green; */
        position: relative;
    }
    .error__rightMost{
        width: 30vw;
    }
}