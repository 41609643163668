.bookCard {
    background-color: white;
    box-shadow: 0px 0px 7px 1px #ede7e7;

    .Title {
        font-size: 16px;
    }
}

.frame {
    // display: flex;
}

.containerimage {
    position: relative;
    // margin-left: 15px;
    // border: 5px solid green;
} 
.containertext {
    padding-left: 5%;
}
.about_book_autor{
    // border : 1px solid red;
    width : 100%;
    position: absolute;
    bottom: -15%;
    left : 50%;
    transform: translate(-50%, -15%);
}
.AutorImg {
    width: 90%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 20px;
    margin-left: 5%;
    object-fit: contain;
    // left : 50%;
    // transform: translate(-50%,0);
}

.AboutBook-Heading {
    font-size: 32px;
    text-align: left;
}

.AboutBook-Text {
    font-size: 17px;
    text-align: justify;
    // text-size-adjust: 100%;
}

.AlreadyBoughtBtn {
    height: 45px;
    background: #0EAD69;
    color: white;
    width: 80%;
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AlreadyBoughtBtn:hover {
    background-color: #c4f4df;
    color: #0EAD69;
}

.AlreadyBoughtBtn:focus {
    outline: none;
    border: 2px solid #0EAD69;
}

.AlreadyBoughtBtn1 {
    height: 45px;
    background-color: #c4f4df;
    color: #0EAD69;

    width: 30%;
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AlreadyBoughtBtn1:hover {
    background: #0EAD69;
    color: white;

}

.AlreadyBoughtBtn1:focus {
    outline: none;
    border: 2px solid #0EAD69;
}

.BookPreviewBtn {
    height: 45px;
    background: #517A95;
    color: white;
    width: 30%;
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
}

.BookPreviewBtn:hover {

    background-color: #bad7e9;
    color: #517A95;


}

.BookPreviewBtn:focus {
    outline: none;
    border: 2px solid #517A95;
}


.AddBookBtn {
    height: 45px;
    background: #B5322A;
    color: white;
    width: 80%;
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddBookBtn:hover {
    background-color: #f8ebba;
    color: #B5322A;
}

.AddBookBtn:focus {
    outline: none;
    border: 2px solid #B5322A;
}

.AddBookBtn1 {
    height: 45px;
    background: #B5322A;
    color: white;

    width: 30%;
    font-size: 16px;
    font-family: poppins;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddBookBtn1:hover {

    background-color: #f8ebba;
    color: #B5322A;
}

.AddBookBtn1:focus {
    outline: none;
    border: 2px solid #B5322A;
}

//Library
.read_btn {
    background: #B5322A;
    color: white;
    width: 42%;
    font-size: 14px;
    height: 30px;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-weight: 500;
}

.read_btn:hover {
    opacity: 0.9;
}

.read_btn:focus {
    opacity: 0.7;
    outline: none;
}

.myLibaray {
    font-size: 21px;
    color: #B5322A;
}

.myLibaray1:hover {
    cursor: pointer;
    opacity: 0.8;
}

//add to cart
.middle {
    width: 200px;
    transition: .5s ease;
    opacity: 0;
    position: absolute;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    right: 15px;
    bottom: 10px;


}

.container:hover .image {
    opacity: 0.2;
    background: rgba(0, 0, 0, 0.5);
}

.container:hover .middle {
    opacity: 1;
}

.imghoverbtn {
    position: absolute;
    height: 38px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background: #4B4B5B;
    color: white;
    font-family: 'Poppins';
    font-size: 13px;
    border: none;
    font-weight: 500;
    bottom: 132px;
    right: -85px;


}

.ptext {
    font-size: 15px;
}

.imghoverbtn:focus {
    outline: none;
    opacity: 0.8;
    background: #B5322A;

}

.nav-category {
    background: #B5322A;
    height: 438px;
    color: white;
    margin-top: 25px;
    border-radius: 15px;
    margin-right: 15px;
    margin-left: 15px;
    padding-top: 15px;
    margin-bottom: 30px;
}

.New-nav-category {
    margin-top: 25px;
    border-radius: 15px;
    margin-right: 15px;
    margin-left: 15px;
    padding-top: 15px;
    margin-bottom: 30px;
}

.SerachFilterHeading {
    color: #1A1818;
    font-size: 18px;
}

.SerachFilterText {
    color: #1A1818;
    font-size: 14px;
}

.New-nav-category_Heading {
    color: #1A1818;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-top: 20px;

}

.signin_container .nav_category_checkbox_label {
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 300;
    color: #1A1818;
}

.book_heading_category {
    color: #4B4B5B;
    font-size: 25px;

}

.latest-book-btn {
    border: 1px solid #4B4B5B;
    height: 39px;
    color: #4B4B5B;
    background: white;
    width: 154px;
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 500;
    border-radius: 6px;
    padding-left: 13px;

}

.latest-book-btn:focus {
    outline: none;
}

.category_heading_type {
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.category_heading {
    font-size: 13px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;
    margin-bottom: 10px;

}

.categories {
    font-size: 13px;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.categories:hover {
    opacity: 0.9;
    cursor: pointer;
}

.book_img:hover {
    cursor: pointer;
}

@media (max-width: 1106px) {
    .signin_container .nav_category_checkbox_label {
        font-size: 11px;
        font-family: 'Poppins';
    }
    .AutorImg {
        height: 100%;
        width: 90%;
        display: block;
        object-fit: contain;
        // position: static;
        border-radius: 20px;
    }
}

@media (max-width: 980px) {
    .nav-category {
        background: #B5322A;
        height: 875px;
        margin-top: 25px;
        border-radius: 15px;
        /* padding-left: 97px; */
        margin-right: 0px;
        margin-left: 0px;
        padding-top: 15px;
    }

    .category_heading_type {
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .category_heading {
        font-size: 12px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 10px;
        margin-bottom: 20px;

    }

    .categories {
        font-size: 12px;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .AutorImg {
        // height: 100%;
        margin : 0 auto;
        width: 100%;
        display: block;
        position: static;
        border-radius: 20px;
    }
}

@media (max-width: 768px) {
    .New-nav-category {
        display: none;
    }
    .containertext{
        margin-top: 15%;
    }
    .AutorImg {
        // height: 100%;
        margin : 0 auto;
        width: 60%;
        display: block;
        position: static;
        border-radius: 20px;
    }
    
}

@media (max-width: 576px) {
    .BookPreviewBtn {
        width: 100%;
        margin-left: 0px;
    }
    .containertext{
        margin-top: 15%;
    }
    .AutorImg {
        // height: 100%;
        margin : 0 auto;
        width: 60%;
        display: block;
        position: static;
        border-radius: 20px;
    }
}