.nofile{
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: auto; */
    margin-top: 15%;

}
.SorryText{
    color: #D8DDE2;
    font-size: 37px;

}
.SorryTextS{
    color: #D8DDE2;
    font-size: 14px;

}
.SorryTextM{
    color: #D8DDE2;
    font-size: 25px;

}