.mlmr25{
    margin-left: 25px;
    margin-right: 25px;
}

.aboutus_tag{
    font-size: 18px;
    margin-bottom: 15px;
}
.aboutus_heading{
    font-size: 23px;
    color: #BB423B;
}
.aboutus_card{
    background: #517A95;
    color: white;
    padding-left: 51px;
    padding-right: 51px;
    padding-top: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    border-radius: 12px;

}
.aboutus_btn_slider{
    border: 1px solid #BB423B;
    height: 59px;
    border-radius: 31px;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}
.aboutus_btn_slider_btn{
    height: 57px;
    border-radius: 29px;
    width: 16%;
    border: 0;
    background: white;
    color: #4B4B5B;
    font-size: 17px;
    font-family: 'Poppins';
    font-weight: 400;
}
.aboutus_btn_slider_btn_active{
    height: 57px;
    border-radius: 29px;
    width: 16%;
    border: 0;
    background-color:#BB423B ;
    color: white;
    font-size: 17px;
    font-family: 'Poppins';
    font-weight: 400;
}
.aboutus_btn_slider_btn_big{
width: 22%;
}
.aboutus_btn_slider_btn_bigs{
    width: 20%;
    }
.aboutus_btn_slider_btn:focus{
    outline: none;
}
.aboutus_btn_slider_btn:hover{
    background-color:#BB423B ;
    color: white;
}
.aboutus_btn_slider_btn:active{
    background-color:#BB423B ;
    color: white;
}
.moreDetail_btn{
    height: 59px;
    border: 0;
    background-color: #BB423B;
    border-radius: 11px;
    color: white;
    font-size: 17px;
    font-family: poppins;
    width: 181px;
}
.center_para{
    padding-left: 50px;
    padding-right: 50px;
}
@media (max-width: 1355px) {
    .aboutus_btn_slider_btn{
        font-size: 15px;

    }
}
@media (max-width: 1255px){
.aboutus_btn_slider_btn {
    font-size: 11px;
}
}
@media (max-width: 867px){
    .aboutus_btn_slider_btn {
        font-size: 9px;
    }
    
    }