.dashboard_link {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.dropbtn {
    margin-right: 5px;
    width: 67px;
    height: 19px;
    overflow: hidden;
    padding-bottom: 19px;
    color: #BB423B !important;
    font-size: 14px;
    font-size: ff;
    border: none;
    background: none;
    font-family: 'Poppins';
    font-weight: 700;
}

.dropbtn:focus {
    outline: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    box-shadow: 0px 0px 3px 0px #000;
    z-index: 1;
    right: 70%;
    // right: calc(50% - 75px);
    font-size: 13px;
    margin-top: 7px;
    font-family: 'Poppins';
    font-weight: 600;
    display: none;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
    cursor: pointer;
    opacity: 0.8;
}

.Hov:hover {
    opacity: 0.8;
    cursor: pointer;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {}


.popup-class{
    // padding-top: 5%;
    // padding-Bottom: 3%;
    // width: 30%;
    position: relative;
    border-radius: 20px;
}
.your-close-button-class{
    border-radius: 100%;
    margin-top : 7%;
    margin-right: 7%;
    // border : 1px solid red;
    color : white;
    // font-size: 32px;
    font-weight: 900;
    background-color: #B5322A;
}
.no-border {
    border: 0 !important;
}

.my-title {
    color: black;
    text-transform: capitalize;
    font-weight: 600;
    // font-size: 36px;
}
.my-htmlText {
    font-size: 15px;
}
.my-text {
    color: #B5322A
}

.my-button {
    color: red
}

.my-confirm-button-class {
    padding-left : 35px;
    padding-Right : 35px;
    padding-top: 10px;
    padding-Bottom: 10px;
    border : 1px solid #BB423B;
    // border-radius: 0;
    background-color: #B5322A;
    color : white;
    font-size: 16px;
    // width : 10%
    // height : 20px;
    border-radius: 10px;
    outline: 0 !important;
    margin-bottom: 15%;
}
.shoppingCartImg {
    margin-top: -14px;
    width: 46px;

}

.shoppingCartImg {
    cursor: pointer;
    opacity: 0.8;
}

.shoppingCartImgCount:hover {
    cursor: pointer;
}

.BottomShoppingCart:hover {
    cursor: pointer;
    opacity: 0.8;
}

.shoppingCartImgCount {
    text-align: center;
    position: absolute;
    top: -9px;
    color: white;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 500;
    right: calc(100% - 51px);
}

.shoppingCartImgCountBottom {
    text-align: center;
    position: absolute;
    top: 40%;
    color: #B5322A;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 900;
    // right: calc(100% - 2px);
    left: 60%;
    transform: translate(-60%, -40%);

}

.header_sepration {
    height: 3px;
    width: 100%;
    /* background: gray; */
    position: relative;
    z-index: 1;
    margin-top: 194px;
    margin-bottom: 7px;
    box-shadow: 0px 4px 5px 2px #dddcdc;
}

.colapse_lib {
    position: absolute;
    left: 13%;
    font-size: 13px;
    color: black;
    /* margin-top: 10px; */
    /* border: 1px solid #e1aca2; */
    box-shadow: 0px 0px 10px 0px #d4d4d4;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;

}

.colapse_lib:hover {
    cursor: pointer;
    opacity: 0.8;


}

.headerTabsContainer {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.header_tabssNEw {
    width: 16.66%;

}

.header_tabss {
    width: 13.66%;
}

.loginname {
    width: 67px;
    height: 19px;
    overflow: hidden;
    padding-bottom: 19px;
}

.loginname:hover {
    border-bottom: 4px solid;

}

.loginname:focus {
    border-bottom: 4px solid;

}

.locationName {
    height: 20px;

}

.header_tabss_small {
    width: 13%;
}

.header_tabss_large {
    width: 15%;
}

.header_tabss_extralarge {
    width: 17%;
}

.header_tabss_btn {
    // background-color: #BB423B;
    // color: white;
    // border: none;
    // font-size: 14px;
    // height: 33px;
    // border-radius: 5px;
    // margin-top: 10px;
    //     padding-left: 10px;
    //     padding-right: 10px;
    font-family: poppins;
    font-weight: 400;
    color: #1A1818;
    font-size: 16px;
    border: none;
    background: none;
    padding-bottom: 2px;
}

.header_tabss_btn:hover {
    color: #BB423B;
    border-bottom: 3px solid #BB423B;
    font-weight: 400;
}

.header_tabss_btn_active {
    font-family: poppins;
    font-weight: 400;
    color: #1A1818;
    font-size: 16px;
    border: none;
    background: none;
    padding-bottom: 2px;
    color: #BB423B;
    border-bottom: 3px solid #BB423B;
    font-weight: 700;
}

.header_tabss_btn:focus {
    outline: none;
    // background-color: white;
    // color: #BB423B;
    // border: 1px solid #BB423B;
    // font-size: 13px;



}

// .header_tabss_btn:hover{
//     background-color: white;
//     color: #BB423B;
//     border: 1px solid #BB423B;
//     font-size: 12px;


// }

.tooglebtnn {

    position: absolute;
    top: 0px;
    right: 0px;
    border: none;

}
.HeaderTabsContain{
    display: block;
}

.tooglebtnn:hover {
    background-color: white !important;
    opacity: 0.8;
    cursor: pointer;
}

.tooglebtnn_div {
    height: 52px;
}
.responsive-cart-icon{
    display: none;
}
.responsive-login-Register{
    display: none;
}

.mlmr {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: #BB423B;
}

.nav_hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid white;
}

.beforecolpasemain {
    display: block;
}

.beforecolpasenav {
    display: none;
}

//colpase
.navbar-inverse {
    background-color: white;
    border-color: #080808;
    border: none;
}

.navbar_li_text {
    color: white;
    font-size: 17px;
    margin-left: 20px;
}

.navbar_li_text:hover {
    cursor: pointer;
    opacity: 0.9;
}

.navbar-inverse {
    background-color: white;
    border-color: #080808;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
}

.filtericon:hover {
    cursor: pointer;
    opacity: 0.9;
}

.navbarcopyright {
    font-size: 10px;
    color: white;
}
.social-logos{
    display: none;
}
.nav_hr{
    display: none;
}
//modal
.modal_heading {
    color: #BB423B;
    font-size: 19px;
}

.modal_semi_heading {
    color: #BB423B;
    font-size: 15px;
}

.modal_btn {
    height: 27px;
    border: 2px solid #BB423B;
    background: white;
    border-radius: 14px;
    font-size: 10px;
    font-family: 'Poppins';
    font-weight: 600;
    width: 100%;
    color: black;

}

.modal_btn:focus {
    outline: none;
    background-color: #BB423B;
    color: white;
}

.modal_input {
    width: 100%;
    font-family: 'Poppins';
    font-weight: 500;
    height: 36px;
    border-top: none;
    border-left: none;
    border-bottom: 3px solid #BB423B;
    border-right: none;
}

.modal_ApplyBtn {
    height: 45px;
    border: 2px solid #BB423B;
    background: white;
    border-radius: 36px;
    font-size: 10px;
    font-family: 'Poppins';
    font-weight: 700;
    width: 100%;
    color: black;
    font-size: 14px;
}

.modal_ApplyBtn:focus {
    outline: none;
    background-color: #BB423B;
    color: white;
}

.crossbtn_modal {
    position: absolute;
    top: 8px;
    right: 9px;
}

.crossbtn_modal:hover {
    opacity: 0.8;
    cursor: pointer;
}

.responsivelogout{
    display: none;
}

.signin_container .checkbox_label1 {
    font-size: 11px;
    font-family: 'Poppins';
    /* color: black; */
}

@media (max-width: 1500px) {
    // .shoppingCartImgCount {

    // left: 91%;
    // }
}

@media (max-width: 1366px) {
    // .shoppingCartImgCount {

    //     left: 91%;
    // }
}

@media (max-width: 1235px) {
    .dashboard_link {
        width: 100%;
    }
}

@media (max-width: 1144px) {
    .dashboard_link {
        width: 100%;
    }

    .header_tabss_btn {

        font-size: 10px;
        padding: 5px;

    }

    // .shoppingCartImgCount {

    //     left: 111%;
    // }

}

@media (max-width: 1017px) {
    // .shoppingCartImgCount {

    //     left: 123%;
    // }
}

@media (max-width: 914px) {
    // .shoppingCartImgCount {

    //     left: 123%;
    // }
    .shoppingCartImg {
        display: none;
    }
}

@media (max-width: 880px) {
    .beforecolpasemain {
        display: block;
    }

    .shoppingCartImgCount {
        text-align: center;
        position: absolute;
        top: -3px;
        color: white;
        font-size: 10px;
        font-family: 'Poppins';
        font-weight: 500;
        right: calc(100% - 41px);
    }

    .shoppingCartImg {
        margin-top: -6px;
        display: block;
        width: 34px;
    }

    // .shoppingCartImgCount {
    //     left: 17%;
    //     top: -3px;
    //     font-size: 10px;
    // }
    .beforecolpasenav {
        display: block;

    }

    .header_sepration {
        margin-top: 30px;
    }

    .navbar-toggle {
        display: block;
    }
}

@media (max-width: 800px) {
    .header_tabss_small {
        width: 8%;
    }

    .header_tabss_large {
        width: 13%;
    }

    .header_tabss_extralarge {
        width: 20%;
    }

    .navbar-toggle {
        display: block;
    }

}



@media (max-width: 733px) {
    // .header_tabss_small {
    //     width: 9%;
    // }
    // .header_tabss {
    //     width: 14.5%;
    // }

}

@media (max-width: 691px) {

    .header_tabss_btn {
        font-size: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-toggle {
        display: block;

    }
}

@media (max-width: 650px) {
    .header_tabss_small {
        width: 25%;
    }

    // .shoppingCartImgCount {
    //     left: 57%;
    //     top: -3px;
    //     font-size: 10px;
    // }
    .header_tabss {
        width: 25%;
    }

    .header_tabss_extralarge {
        width: 25%;
    }

    .header_tabss_large {
        width: 25%;
    }

    .navbar-toggle {
        display: block;
    }
}

@media (max-width: 621px) {
    .beforecolpasemain {
        display: block;
    }

    .beforecolpasenav {
        display: block;

    }

    .header_tabss_btn {
        font-size: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar-toggle {
        display: block;

    }

    // .shoppingCartImgCount {
    //     left: 59%;
    //     top: -3px;
    //     font-size: 10px;
    // }
}

@media (max-width: 521px) {

    .navbar-toggle {
        display: block;
        right: -22px;

    }

    // .shoppingCartImgCount {
    //     left: 87%;
    //     top: -3px;
    //     font-size: 10px;
    // }
}


// MEDIA QUERY FOR USER HEADER
@media (max-width: 850px) {

    .HeaderTabsContain .row{
        background-color: #c6625d;
        flex-direction: column;
        border: 1px solid white;
        margin-top: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .header_tabss_small{
        width : 100%
    }
    .header_tabssNEw{
        width: 100%;
    }
    .header_tabss_small button{
        color: white;
    }
    .header_tabssNEw button{
        color: white;
    }
    .social-logos{
        display: block;
    }
    .nav_hr{
        display: block;
        opacity: 0.2;
    }
    .logoutDrop{
        display: none;
    }
    .login-drop{
        display: none;
    }
    .country_div{
        display: none;
    }
    .responsivelogout{
        display: block;
    }
    .responsive-login-Register{
        display: block;
    }
}
