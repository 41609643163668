.footer{
    background-color: #474747;
    color: white;
    z-index: 2;
    position: relative;
}
.footer-input{
    height: 39px;
    font-size: 12px;
    border-radius: 39px;
    border: none;
    box-shadow: 0px 0px 10px 0px #b7b3;
}
.footer-input:focus{
outline: none;
}
.footer_subscribe_btn{
    position: absolute;
    right: 17px;
    top: 2px;
    background: #BB423B;
    color: white;
    border: none;
    border-radius: 39px;
    height: 35px;
    width: 93px;
}
.footer_subscribe_btn:focus{
    outline: none;
}
.footer-item-heading{
    font-size: 15px;
}
.footer-item{
    color: #fff
}
.footer-item:hover{
    cursor: pointer;
    opacity: 0.8;
}
.footer_cotactus_text{
    font-size: 30px;

}
.footer_contact_input{
    height: 47px;
    font-size: 15px;
    /* border-radius: 39px; */
    border: none;
    box-shadow: 0px 0px 10px 0px #b7b3;
    color: #767676;
    border-radius: 34px;
}
.footer_contact_input:focus{
    outline: none;
}
.footer_msg_input{
    height: 120px;
    font-size: 15px;
    border: none;
    box-shadow: 0px 0px 10px 0px #b7b3;
    color: #767676;
    border-radius: 21px;
    padding-top: 20px;


}
.footer_msg_input:focus{
    outline: none;
}
.footer_send_btn{
    height: 47px;
    width: 143px;
    color: white;
    font-size: 17px;
    border: none;
    background: #BB423B;
    border-radius: 24px;
    margin-top: 20px;
}
.footer_send_btn:hover{
    opacity: 0.8;
    cursor: pointer;
}
.footer_subscribe_btn:hover{
    opacity: 0.8;
    cursor: pointer;
}
.footer_bottom{
    background-color: #BB423B;
    color: white;
    text-align: center;
    font-size: 13px;
}
.socialmediaicon{
    opacity: 1;
}
.socialmediaicon:hover{
    opacity: 0.8;
    cursor: pointer;
}