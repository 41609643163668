.order-button{
    background-color: #B5322A;
    border: none;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-size: 16px;
}
.divider__paragraph{
    border: 2px solid #B5322A;
    width : 10%;
    margin-left: 0px;
    background-color: #B5322A;
    border-radius: 5px;
}

.inputBox{
    position: relative;
}
.inputBox .stripe_input{
    border : 2px solid #B5322A;
    border-radius: 5px;
    padding: 15px;
    outline: none;
    color: black;
    width: 100%;
}
.inputBox span{
    position: absolute;
    left : 30px;
    padding: 5px;
    pointer-events: none;
    font-size: 1em;
    color: #B5322A;
    text-transform: uppercase;
    top : 25%;
    transition: 0.5s;
}
.back__btnHolder{
    position: relative;
}
.back__btnHolder img{
    position: relative;
    left: 50%;
    top : 50%;
    transform: translate(-50%,-50%);
}