.pubicon{
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.PublisherRegisterBtn{
    width: 221px;
    height: 54px;
    border-radius: 30px;
    border: none;
    background: #BB423B;
    font-size: 17px;
    font-family: 'Poppins';
    font-weight: 600;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}
.PublisherRegisterBtn:focus{
    outline: none;
    opacity: 0.8;
}
.pubhead{
    font-size: 16px;
    color: #444444;
}
.pubtext{
    font-size: 14px;
    color: #444444;
}

.imgwth{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}
.mlmr150{
    margin-left: 150px;
    margin-right: 150px;
  
    margin-bottom: 50px;
    margin-top: 20px;

}

//privacyPolicy
.PrivacyPolicyContainer{
    .heading{
    font-size: 20px;
    }
    .text{
        font-size: 15px;
    }

}
@media (max-width: 767px) {
    .mlmr150{
        margin-left: 20px;
        margin-right: 20px;
      
        margin-bottom: 50px;
        margin-top: 20px;
    
    }
}