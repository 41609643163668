.preview_container{
    position: absolute;
    height: 100% !important;
    width: 100% !important;
}
.upnav{
    font-size: 13px;
    opacity: 0.54;
    font-size: 13px;
    opacity: 0.54;
    font-family: 'Poppins';
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
}
.aboutbook_title{
    font-size: 31px;
    color: #444444;
    line-height: 0.8;
    margin-bottom: 0px;
}
.book_imgg{
    height: 462px;
    width: 90%;
}
.w_aboutbook{
    width: 25%;

}
.topnav-w50{
    width: 55%;

}
.aboutbook_author{
    font-size: 24px;
    color: #444444;
    margin: 0px;
 }
.aboutbook_rating{
     font-size: 12px;
 }
.aboutbook_price_margin{
    margin-top: 74px;
}
.aboutbook_price_text{
    font-size: 22px;
}
.aboutbook_price_delte{
    color: #CC4141;

}
.aboutbook_price_actual{
    color: #2A9F1D ;
    font-size: 32px;
}
.aboutbook_preview{
    background-color: #BB423B;
    color: white;
    width: 154px;
    height: 39px;
    font-size: 15px;
    border-radius: 6px;
    border: none;
}
.aboutbook_preview:focus{
    outline: none;
}
.aboutbook_preview:hover{
    opacity: 0.8;
}
.aboutbook_addcart{
  
    // position: absolute;
    z-index: 1;
    box-shadow: 0px 0px 1px 0px #3c3838;
    height: 384px;
    right: 27px;
    width: 89%;
    border: 1px solid lightgrey;
    padding-top: 10px;
    border-radius: 15px;


}
.aboutbook_btn_slider {
    border: 1px solid #BB423B;
    height: 59px;
    border-radius: 31px;
    width: 57%;
    /* margin-left: auto; */
    margin-right: auto;
    overflow: hidden;
    visibility: hidden;
}
.aboutbook_btn_slider_btn {
    height: 57px;
    border-radius: 29px;
    width: 33.33%;
    border: 0;
    background: white;
    color: #BB423B;
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 400;
}
.aboutbook_btn_slider_btn:hover{
    background-color:#BB423B ;
    color: white;
}
.aboutbook_btn_slider_btn:focus{
    outline: none;
}
.aboutbook_btn_slider_btn_active{
    height: 57px;
    border-radius: 29px;
    width: 33.33%;
    border: 0;
    background-color:#BB423B ;
    color: white;
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 400;
}
.aboutbook_publisher {
    font-size: 18px;
    color: #BB423B;
    margin: 0px;
}

.aboutbook_btn_slider_btn_active:focus{
    outline: none;
}
.aboutbook_addcard_title{
    color: #444444;
    font-size: 21px;
    opacity: 0.31;
}
.aboutbook_addcard_titlee{
    color: #444444;
    font-size: 21px;
    margin-bottom: 0px;
}
.aboutbook_addcard_authorname{
    color: #444444;
    font-size: 15px;
}
.aboutbook_addcard_btn{
    height: 43px;
    background-color: #BB423B;
    font-size: 13px;
    border: none;
    color: white;
    margin-top: 25px;
}



.about_book_tabs{
    font-size: 17px;
    opacity: 0.5;
    color: #333435;
    
}
.about_book_tabs:hover{

    opacity: 1;
    color: #BB423B;
    
}
.about_book_tabs.active{
    opacity: 1;
    color: #BB423B;

}
.topnav a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 0px;
    text-decoration: none;
    font-size: 14px;
    border-bottom: 1px solid #333435;
  }
.topnav a:hover {
    border-bottom: 1px solid #BB423B;
  }
  
  .topnav a.active {
    border-bottom: 1px solid #BB423B;
  }
  .about_book_text{
    font-size: 16px;
    line-height: 1.5;
    color: #444444;
    text-align: center;
  }
  .about_book_autor{
      font-size: 14px;
      opacity: 0.87;
      color: #444444;
  }
  .about_book_heading{
      font-size: 26px;
      color: #444444;
  }
  


  @media (max-width: 1225px) {
    .aboutbook_price_margin {
        margin-top: 57px;
    }
  }
  @media (max-width: 1100px) {
    .aboutbook_price_margin {
        margin-top: 25px;
    }
    .aboutbook_title {
        font-size: 25px;
    }

.aboutbook_author {
    font-size: 17px;
}
.aboutbook_price_text {
    font-size: 15px;
}
.aboutbook_addcard_titlee {
    font-size: 16px;
}
  }
  @media (max-width: 992px) {
    .aboutbook_addcart {
    //  position: relative;
        width: 100%;
    }
    .aboutbook_btn_slider{
        width: 100%;
    }
    .aboutbook_price_actual {
        color: #2A9F1D;
        font-size: 16px;
    }
    .aboutbook_publisher {
        font-size: 14px;
        color: #BB423B;
        margin: 0px;
    }
    
  }
  @media (max-width: 990px) {

    .topnav-w50{
        width: 100%;
    
    }
    .topnav a {
       
        font-size: 14px;
        padding: 13px 0px;

    }
  }

  @media (max-width: 768px) {
        .nav-category{
            display: none
        }
    
      
    
}

  @media (max-width: 683px) {
    .aboutbook_addcart {
     
        width: 100%;
        margin-left: 25px;
        margin-right: 25px;
    }
    .topnav a {
       
        font-size: 12px;
    }
    
  }

  @media (max-width: 537px) {
    .aboutbook_addcart {
     
        width: 100%;
    }
    .topnav a {
       
        font-size: 8px;
        opacity: 1;
    }
    .aboutbook_btn_slider_btn{
        font-size: 10px;
    }
    
  }

  @media (max-width: 400px) {
      .text_centerr{
          text-align: center;
      }
      .upnav {
        font-size: 10px;
        opacity: 0.54;
    }
    .mlmr25 {
        margin-left: 10px;
        margin-right: 10px;
    }
    .aboutbook_addcart {
        width: 100%;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
    }
    .book_imgg{
        width: 100%;
    }
  }
