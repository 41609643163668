.nav_logo_div{
    padding-top: 20px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
}
.search_icon:hover{
  cursor: pointer;
}
.toolttip_text:hover{
cursor: pointer;
opacity: 0.8;

}
.pointHov:hover{
  cursor: pointer !important;
}
.signupPreviewDiv{
  position: absolute;
    right: 0%;
    left: 0%;
    bottom: 0px;
    margin: auto;
    text-align: center;
    background: #517A95;
    color: white;
    width: 60%;
    padding-top: 7px;
    padding-bottom: 5px
}
.signupPreviewDivMobile{
  position: absolute;
    right: 0%;
    left: 0%;
    bottom: 0px;
    margin: auto;
    text-align: center;
    background: #517A95;
    color: white;
    width: 60%;
    padding-top: 7px;
    padding-bottom: 5px;
    display: none;
}
.buysignup{
  font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
    color: white;
}
.buysignup:hover{
  opacity: 0.9;
  cursor: pointer;
}
.signupPreview{

  text-align: center;
  font-family: 'Poppins';
  font-size: 13px;
}
// view: {
//   height: "95%",
//   overflowX:"hidden",
//   width: "calc(100% - 40px)",
//   marginLeft: "40px",


// }
//modal

.epubmodalheading{
  font-size: 33px;
  line-height: 1;


}
.epubmodaltext{
  font-size: 18px;
  line-height: 1;

}
.epubmodalimg{
  width: 200px;

}
.epubmodalbtn{
  width: 150px;
  height: 36px;
  font-size: 15px;
  color: white;
  background: rebeccapurple;
  border: none;
}
.epubmodalbtn:hover{
  background-color: #BB423B;
  opacity: 0.8;
}
.epubmodalbtn:focus{
  outline: none;
  background-color: #BB423B;

  opacity: 1;
}
.epubb-view{
  height: 95%;
  overflow-x: hidden;
  width: calc(100% - 28px);

}
.epubb-viewUrdu{
  height: 95%;
  overflow-x: hidden;
  width: calc(100% - 40px);
  margin-left: 30px;

}
.back_btn:hover{
    cursor: pointer;
}
.back_btnUrdu:hover{
  cursor: pointer;
}
.back_btn{
  margin-left: -1px;
  margin-top: 19px;
  height: 18px;
  position: absolute;
}
.back_btnUrdu {
  margin-left: -1px;
  margin-top: 19px;
  height: 18px;
  position: absolute;
  position: absolute;
  right: 18px;
  transform: rotate(180deg);
}
.Setiingicon{
    height: 23px;

}
.SettingIconDiv:hover{
    cursor: pointer;
    border-bottom: 1px solid gray;
    padding-bottom: 5px;

}
.SettingIconDivActive{
    cursor: pointer;
    border-bottom: 1px solid gray;
    padding-bottom: 5px  
}
// .navlogo{
//     margin-top: 20px;

// }
.navlogo{
  height: 80px;
  width: 161px;
}
.epub_navbar_li_text{
  color: black;
  font-size: 17px;
  margin-left: 20px;
}
.epub_tooglebtnn{
  display: block;
    background: black;
}
.epub_list{
  width: 75% !important;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    padding: 3px;
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.nav_book_div{
    height: 62px;
    border: 1px solid #707070;
    border-radius: 7px;
    margin-top: 30px;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.nav_book_div:hover{
  cursor: pointer;
}

.epub_nav_hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid gray;
  width: 86%;
    margin-left: auto;
    margin-right: auto;
}
.nav_book_title{
  font-size: 10px;
}
.nav_book_author{
    font-size: 9px;
}
.epub_nav_book_author{
    position: absolute;
    bottom: 9px;
    left: 56px;
    font-size: 11px;
}
.img-w-100Urdu {
  width: 100%;
}
.epub_listUrdu {
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  padding: 3px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 64px;

}
.navbar-nav{
  cursor: pointer;
}
.nav_book_titleUrdu {
  font-size: 10px;
  text-align: left;
  position: absolute;
  left: 56px;
  top: 20px;
}
.nav_book_imgUrdu {
  height: 52px;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
  position: absolute;
  left: 1px;
}
.vertical-center{
    margin-top: auto;
    margin-bottom: auto;
}
.nav_book_img{
    height: 52px;
    margin-top: 3px;
    margin-left: 10px;
    margin-right: 10px;
}
.dropdown_icon{
position: absolute;
    right: 17%;
}
.dropdown_icon:hover{
    cursor: pointer;
}
.cross_round{
    height: 32px;
    position: absolute;
    width: 32px;
    right: 8px;
    top: 24px;
    background: #BB423B;
    border-radius: 50%
}
.cross_round:hover{
    cursor: pointer;
}
.cross_roundUrdu {
  height: 32px;
  position: absolute;
  width: 32px;
  right: -14px;
  left: 5px;
  top: 24px;
  background: #BB423B;
  border-radius: 50%;
}
.cross_roundUrdu:hover{
  cursor: pointer;
}
.cross-text{
        font-family: poppins;
        font-weight: 600;
        font-size: 13px;
        
        color: white;
        margin-top: 6px;
        margin-left: 12px;
}
.toolttip{
    background: #BB423B;
    width: 60px;
    height: fit-content;
    position: absolute;
    right: -1px;
    top: calc(69vh - 328px);
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 10px;
}
.toolttipUrdu{
  background: #BB423B;
  width: 60px;
  height: fit-content;
  position: absolute;

  top: calc(69vh - 328px);
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
}
.search_icon{
    height: 19.5px;
    width: 14.5px;
}
.dissearch_icon{
  height: 19.5px;
  width: 14.5px;
}
.Icon_div{
    background: #BB423B;


}
.Icon_div_active{
  background: black ;
}

.Icon_div:hover{
    background-color: black;
}
.Icon_div:active{
    background-color: black;
}
.Icon_div_Active{
    background-color: black;


}
.toolttip_text{
    font-size: 9px;
    color: white;
    margin-top: 2px;
}
.distoolttip_text{
  font-size: 9px;
  color: white;
  margin-top: 2px;
}


.renderDiv{
  width: calc(100% - 252px);
  margin-left: 20px;
  margin-right: 20px;
  height: calc(100% - 37px) !important;
  position: absolute;
  right: -17px;
    
}
.renderDivUrduApp{
  width: calc(100% - 253px);
  margin-left: 20px;
    margin-right: 20px;
    height: calc(100% - 0px) !important;
    position: absolute;
    left: -18px;

  
}
.epub-container{
  overflow-x: hidden !important;
  width: 100% !important;
}
.renderNav{
  position: absolute;
  left: 1px;
  top: 0px;
  bottom: 0px;
  z-index: 0;
  width: 244px;
  overflow: hidden auto;
  height: calc(100% - 77px);
  background: rgb(242, 242, 242);
  padding: 2px 0px;
  direction: rtl;
}
.renderNavUrduApp{
    position: absolute;
    right: 4px;
    top: 55px;
    bottom: 0px;
    z-index: 0;
    width: 245px;
    overflow: hidden auto;
    height: calc(100% - 98px);
    background: #f2f2f2;
    padding: 2px 0px;
    text-align: right;
    border: none;

}
.renderNavEngApp{
  position: absolute;
  right: 4px;
  top: 55px;
  bottom: 0px;
  z-index: 0;
  width: 245px;
  overflow: hidden auto;
  height: calc(100% - 98px);
  background: #f2f2f2;
  padding: 2px 0px;
  text-align: left;
  border: none;

}
.chapter_headingWhite{
  margin: 0px;
  color: #444444;
  font-size: 13px;
  margin-left: 21px;
  font-family: 'Poppins';
  font-weight: 600;

}

.chapter_headingBlack{
  margin: 0px;
  color: white;
  font-size: 13px;
  margin-left: 21px;
  font-family: 'Poppins';
  font-weight: 600;
}
.chapter_titleWhite{
  margin-left: 20px;
  font-size: 12px !important;
  padding: 0px !important;
  font-family: poppins !important;
  font-weight: 300 !important;
  color: #444444 !important;
  margin-right: 20px;

}
.chapter_titleWhiteUrdu {
  margin-left: 20px;
    font-size: 18px !important;
    padding: 0px !important;
    font-family: 'MehrNastaliq' !important;
    font-weight: 500 !important;
    color: #444444 !important;
    margin-right: 20px;
}
.chapter_titleBlack{
  margin-left: 20px;
  font-size: 12px !important;
  padding: 0px !important;
  font-family: poppins !important;
  font-weight: 300 !important;
  color: white !important;
  margin-right: 20px;

}
.chapter_titleBlackUrdu{
  margin-left: 20px;
  font-size: 18px !important;
  padding: 0px !important;
  font-family: MehrNastaliq !important;
    font-weight: 500 !important;
    color: #fff !important;
    margin-right: 20px;

}
// .chapter_titleWhiteUrdu {
//   margin-left: -17px;
//   font-size: 10px !important;
//   padding: 0px !important;
//   font-family: poppins !important;
//   font-weight: 300 !important;
//   color: #444444 !important;
//   text-align: right !important;
// }
// .chapter_titleBlackUrdu{
//   margin-left: 20px;
//   margin-left: -17px;
//   text-align: right !important;


//   font-size: 10px !important;
//   padding: 0px !important;
//   font-family: poppins !important;
//   font-weight: 300 !important;
//   color: white !important;
// }
.nav_divBarWhite{
    background-color: white;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}
.nav_divBarWhite:focus{
  background-color: #F1F1F1 !important;
    cursor: pointer;
    outline: none;

}
.nav_divBarWhite:hover{
    background-color: #F1F1F1;
    cursor: pointer;
}
.nav_divBarBlack{
  background-color: black;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
}
.d-ltr{
  direction: ltr;
}
.nav_divBarBlack:focus{
  background-color: #707070 !important;
    cursor: pointer;
    outline: none;

}
.nav_divBarBlack:hover{
  background-color: #707070;
  cursor: pointer;

}
.searchbar{
    background: #747474;
    color: white;
    position: absolute;
    bottom: 0px;
    border: 1px solid #003459;
    height: 36px;
    width: 50%;
    left: 25%;
    padding-left: 30px;
    font-size: 12px;



}
.left-btn{
  
  background: #BB423B;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 7px;
  padding-bottom: 10px;
  border: none;
}
.left-btn:focus{
    outline: none;
    opacity: 0.8;

}
.left-btn:hover{
  opacity: 0.8;
}
.bottomDiv{
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.bottomDivRight{
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.pervious_text{
    font-size: 11px;
    color: #BB423B;

}

.searchbar::placeholder{
    color: white;
    font-size: 12px;
}
.searchbar:focus{
    outline: none;
}
.innerSearchIcon{
    position: absolute;
    bottom: 10px;
    left: 25.7%;

    bottom: 12px;
}
.navheight{
    height: 94% !important;
}
.exit-btn{
  width: 245px;
  left: 1px;
    height: 41px;
    position: absolute;
    /* top: 2px; */
    bottom: 37px;
    border: 1px solid #003459;
    color: #D04A4A;
    font-size: 12px;
}
.exit-btnUrdu{
  width: 248px;
  right: 1px;
  height: 41px;
  position: absolute;
  /* top: 2px; */
  bottom: 37px;
  border: 1px solid #003459;
  color: #D04A4A;
  font-size: 12px;
}
.exit-btn:focus{
    outline: none;
}
.exit-btn:hover{
    opacity: 0.9;
}

.exit_icon{
    position: absolute;
    top: 11px;
    left: 31px;
    
}
.showdiv{
    z-index: 3;
}
p.Justified-text, li.Justified-text{  
     color: white !important;
}
p.Justified-text{
    color: white !important;
}
.translateDiv{
    background: #747474;
    color: white;
    position: absolute;
    bottom: 0px;
    border: 1px solid #003459;
    height: 100px;
    width: 60%;
    left: 20%;
    font-size: 12px;
    border: 2px solid #003459;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 1;
}
.translateSmallText{
    font-size: 8px;
    margin-bottom: 3px;
}
.translateMediumText{
    font-size: 13px;
    margin-bottom: 3px;

}
.play:hover{
    cursor: pointer;
}
.footerbtm{
    background-color: #BB423B;
    color: white;
    text-align: center;
    font-size: 13px;
    z-index: 3;
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: 4%;
}
.bookmark-img{
    margin-left: 10px;
    width: 8%;
    position: absolute;
    z-index: 1;

}
.bookmark-imgUrdu {
  margin-left: 10px;
  width: 8%;
  position: absolute;
  right: 13px;
  z-index: 1;

}
.footertextt{
    font-size: 12px;
    padding-top: 7px;
}
.whitebackground{
  background-color: white;
}
.blackbackground{
  background-color: black;
}
.clrWhite{
  color: white !important;
}
.clrBlack{
  color: black;
}
.nav_hrr{
  border-bottom: 2px solid lightgrey;
  margin-left: auto;
  margin-right: auto;
  width: 86%;
  margin-top: 8px;}
  .toogle_sidebar_btn{
    display: block;
   
  }
  .hamburgersidebar{
    margin-left: 10px;
    margin-top: 16px;
    display: none;

  
  }
  .hamburgersidebar:hover{
    cursor: pointer;
    opacity: 0.8;
  }
  .hamburgersidebarUrdu{
    text-align: right;
    margin-top: 16px;
    margin-right: 10px;
    position: absolute;
    right: 15px;
    display: none;



  }
  .hamburgersidebarUrdu:hover{
    cursor: pointer;
    opacity: 0.8;

  }

  
//page filp

/* width */
.ebubviewcontainer{ 
  overflow-x: hidden;

::-webkit-scrollbar {
    width: 10px;
    overflow-x: hidden;

  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    overflow-x: hidden;

  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #BB423B; 
    border-radius: 10px;
    overflow-x: hidden;

  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
    overflow-x: hidden;

  }
}
// .loader {
//   margin: 100px auto;
//   font-size: 25px;
//   width: 1em;
//   height: 1em;
//   border-radius: 50%;
//   position: relative;
//   text-indent: -9999em;
//   -webkit-animation: load5 1.1s infinite ease;
//   animation: load5 1.1s infinite ease;
//   -webkit-transform: translateZ(0);
//   -ms-transform: translateZ(0);
//   transform: translateZ(0);
// }
// @-webkit-keyframes load5 {
//   0%,
//   100% {
//     box-shadow: 0em -2.6em 0em 0em #d3cbcb, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   12.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.5);
//   }
//   25% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.5), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2);
//   }
//   37.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.5), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2);
//   }
//   50% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.5), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2);
//   }
//   62.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.5), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2);
//   }
//   75% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.5), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2);
//   }
//   87.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.5), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.7), -1.8em -1.8em 0 0em #000000;
//   }
// }
// @keyframes load5 {
//   0%,
//   100% {
//     box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   12.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.5),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   25% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.5), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   37.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.5), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   50% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.5), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   62.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.5), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0 , 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   75% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.5), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2),0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
//   87.5% {
//     box-shadow: 0em -2.6em 0em 0em rgba(0 , 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.5), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.7), -1.8em -1.8em 0 0em #000000,0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.2), 2.5em 0em 0 0em rgba(0 , 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0 , 0, 0, 0.2), 0em 2.5em 0 0em rgba(0 , 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0 , 0, 0, 0.2), -2.6em 0em 0 0em rgba(0 , 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0 , 0, 0, 0.7);
//   }
// }

.sk-circle {
  margin: 200px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #BB423B;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }
.loaderP{
  font-size: 18px;
  width: 400%;
  left: -108%;
  top: 119%;
  position: absolute;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


// .loader {
//     margin: 5% auto 30px;
//   }
  
//   .book {
//     border: 4px solid #FFFFFF;
//     width: 60px;
//     height: 45px;
//     position: relative;
//     perspective: 150px;
//     margin-top: 200px;

//   }
  
//   .page {
//     display: block;
//     width: 30px;
//     height: 45px;
//     border: 4px solid black;
//     border-left: 1px solid black;
//     margin: 0;
//     position: absolute;
//     right: -4px;
//     top: -4px;
//     overflow: hidden;
//     background: #BB423B;
//     transform-style: preserve-3d;
//     -webkit-transform-origin: left center;
//     transform-origin: left center;

//   }
//   .pagebook{
//     margin-top: 42px;
//     margin-left: -19px;     }
//   .book .page:nth-child(1) {
//     -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
//     animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
//   }
  
//   .book .page:nth-child(2) {
//     -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
//     animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
//   }
  
//   .book .page:nth-child(3) {
//     -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
//     animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
//   }
  
  
  /* Page turn */
  
  @-webkit-keyframes pageTurn {
    0% {
      -webkit-transform: rotateY( 0deg);
      transform: rotateY( 0deg);
    }
    20% {
      background: #BB423B;
    }
    40% {
      background: #BB423B;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
    100% {
      background: #BB423B;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
  }
  
  @keyframes pageTurn {
    0% {
      transform: rotateY( 0deg);
    }
    20% {
      background: #4b1e77;
    }
    40% {
      background: rebeccapurple;
      transform: rotateY( -180deg);
    }
    100% {
      background: rebeccapurple;
      transform: rotateY( -180deg);
    }
  }
  
  
  /* Dots */
  
  @-webkit-keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }
  
  @keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }
  .Icon_div_disable{
    cursor: not-allowed;
    background: grey;

  
  }
  .Icon_div_disable:hover{
    cursor: not-allowed;

  }
  @media (max-width: 736px) {
    // .renderNav{
    //   display: none;
    // }
    .signupPreviewDivMobile{
      display: block;
    }
    .signupPreviewDiv{
      display: none;
    }
    .hamburgersidebar{
      display: block;
      position: absolute;
      right: 9px;
    }
    .hamburgersidebarUrduApp{
      display: block;
      position: absolute;
      right: 19px;
      top: 18px;
    }
    .cross_round {
     
      top: 54px;
      right: 12px;
    }
      .cross_roundUrdu {
     
        top: 54px;
        left: 12px;
      
      }
    .renderNavDisplay{
      display: block;
      z-index: 2;
      border-right: 1px solid;
      border-bottom: 1px solid;
      border-top: 1px solid;
    }
    .renderNavDisplayUrdu{
      display: block;
      z-index: 2;
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-top: 1px solid;
    }
   
    .renderNavRemove{
      display: none;
    }
    
    .renderDiv{
      width: calc(100% - 4px);
      margin-left: auto;

    }
    .renderDivUrduApp{
      width: calc(100% - 3px);

    }
    .toogle_sidebar_btn{
      display: block;
     
    }
    .epubb-view{
      height: 95%;
      overflow-x: hidden;
      width: calc(100% - 20px);
      margin-left: 10px;
    
    }
    .epubb-viewUrdu{
      height: 95%;
      overflow-x: hidden;
      width: calc(100% - 40px);
      margin-left: 30px;
    
    }
    
  }

  @media (max-width: 500px) {

  .epubb-view {
    height: 95%;
    overflow-x: hidden;
    width: calc(100% - 10px);
    margin-left: -8px;
}
.epubb-viewUrdu {
  height: 95%;
  overflow-x: hidden;
  width: calc(100% - 11px);
  margin-left: 23px;
}
.back_btnApp{
    margin-left: -1px;
    margin-top: 20px;
    height: 18px;
    position: absolute;
    position: absolute;
    left: 18px;
    transform: rotate(0deg
)
}
.chapternameApp{

  position: absolute;
  top: 13px;
  left: 21px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 17px;

}
.settingDiv{
    height: calc(100vh - 190px);
    padding: 20px;
    text-align: left;

}
.SetiingText{
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    opacity: 0.7;
}
.settingDropDown{
    height: 40px;
    width: 84%;
    margin-left: 3px;
    padding-left: 15px;
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 22px;
    border-radius: 9px;
}
  }