.dashboard-cotainer {
    width: 100%;
    overflow: hidden;
    padding-bottom: 25px;

}

.slideImg {
    height: 340px;
    object-fit: cover;
    object-position: top;
}

.authorCard {
    text-align: center;
    background: #517A95;
    height: 340px;
    padding: 45px 35px;
    color: white;
    border-radius: 10px;

    .text1 {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .text2 {
        font-size: 24px;

    }

    .text3 {
        font-size: 16px;
    }

    .btn {
        font-family: 'Poppins';
        font-weight: 500;
        height: 45px;
        background: white;
        color: #517A95;
        font-size: 16px;
        width: 75%;
        border: none;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .btn:hover {
        background-color: black;
        color: white;
    }

    .LoginLabel {
        color: white;
        margin-left: 4px;
    }

    .LoginLabel:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.countryName {
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 400;
    /* list-style: upper-alpha; */
    text-transform: capitalize;
}

.checkboxContainer {
    margin-top: 35px;


}

.headerLoginBtn {
    height: 46px;
    width: 110px;
    font-family: 'Poppins';
    font-weight: 700;
    background: none;
    color: white !important;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    background-color: #c6625d;
}

.headerLoginBtn:hover {
    background-color: #B5322A;
}

.headerRegisterBtn {
    height: 46px;
    width: 110px;
    font-family: 'Poppins';
    font-weight: 700;
    background: none;
    color: #B5322A !important;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    // border: 1px solid red;
    position: relative;
}

.headerRegisterBtn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 0.1em;
    background-color: #B5322A;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.headerRegisterBtn:hover::after,
.headerRegisterBtn:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
}

.headerRegisterBtn:focus {
    outline: none;
    // border: 2px solid #B5322A;
}


.carousel .thumbs {
    display: none;

}

.carousel .carousel-status {
    display: none;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}

.carousel .slide {
    background: none;
}

.carosl-mt {
    margin-top: 66px;
}

.carousel .control-dots .dot:focus {
    outline: none;
}

.dashboard_header {
    background-color: #BB423B;
    color: white;
    text-align: center;
    font-size: 13px;
    padding: 5px;


}

.footer_Contact_response {
    color: white;
    font-size: 15px;
    line-height: 1.2;

}

.logo_img {
    height: 84px;
    width: 192px;
    margin-top: 10px;
}

.header-cross-img {
    position: absolute;
    top: 3px;
    right: 2%;
}

.header-cross-img:hover {
    cursor: pointer;
}

.dashboard_header_input {
    background-color: #f1f1f1;
    font-size: 17px;
    border: #707070;
    height: 43px;
    color: #A4ACAE;
    z-index: 1;
    height: 60px;
    border-radius: 12px;
    padding-left: 14%;
    padding-right: 20%;


}

.dashboard_header_input::placeholder {
    color: #9b9b9b;
}

.book-author {
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.dashboard_header_input:focus {
    outline: none;
    box-shadow: 0px 0px 2px 0px rgb(82, 24, 24);
}

.dashboard_header_input:hover {
    opacity: 0.9;
}

.filterIcon {
    position: absolute;
    top: 39%;
    right: 7.5%;
    z-index: 1;
    opacity: 0.7;
}

.filterIcon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.searchicon {
    position: absolute;
    left: 8%;
    top: 36%;
    z-index: 1;
    height: 18px;
    width: 18px;
}

.searchicon:hover {
    cursor: pointer;

}

.header_input_verticalLine {
    height: 30px;
    border-left: 2px solid #b1b1b1;
    position: absolute;
    z-index: 1;
    top: 27%;
    right: 16%;
}

.dashboard_tabs {
    font-size: 16px;
    color: #BB423B;
    // margin-right: 80px;
}

.dashboard_tabs_list {
    margin-left: 102px;

}

.dashboard_tabs:hover {
    color: #BB423B;
    opacity: 0.9;

}

.dashboard_tabs:focus {
    color: #BB423B;

}

.dashboard_header_tabs {
    margin-top: 20px;
}

.dashboard_header-text {
    font-size: 14px;
    color: #707070;
    /* margin-left: 2px; */
    margin-right: 20px;
    margin-bottom: 0px;
}

.dashboard_header-sign-text {
    font-size: 14px;
}

.dashboard_header-sign-text:hover {
    cursor: pointer;
}

.round-sign-div {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #BB423B;
    margin-left: 15px;
    margin-top: -5px;



}

.signin-count {
    color: white;
    font-size: 13px;
    margin-top: 7px;
    position: absolute;
    left: 0%;
    right: 0%;
    text-align: center;

}

.login_div {
    margin-left: 15px;
    margin-right: 15px;
}

//banner
.banner_shadow_img {
    position: absolute;
    top: 0px;
    left: 15px;
    height: 100%;


}

.banner-img {
    height: 298px;
}

.banner-logo-icon {
    z-index: 1;
    position: absolute;
    top: 13%;
    left: 5%;
    height: 51px;
    opacity: 0.8;
}

.banner-text {
    font-size: 31px;
    color: white;
    top: 36%;
    position: absolute;
    z-index: 1;
    left: 42px;
    opacity: 0.8;
}

.banner_horizontal_row {
    border: 0;
    border-top: 5px solid white;
    color: white;
    position: absolute;
    top: 53%;
    left: 44px;
    width: 21%;
    z-index: 1;
}

.banner-small-text {
    font-size: 16px;
    color: white;
    z-index: 1;
    position: absolute;
    top: 59%;
    left: 5%;
}

.banner-button {
    position: absolute;
    width: 125px;
    top: 70%;
    height: 32px;
    left: 5%;
    font-size: 12px;
    border: none;
    color: #8B6F32;
    border-radius: 6px;
    z-index: 1;

}

.blog-shadow {
    position: absolute;
    left: 33px;
    z-index: 0;
    top: 2px;
    height: 284px;
    width: 81%;
}

.blog-img {
    height: 298px;
    width: 100%;
    border-radius: 15px;

}

.blog-text {
    font-size: 21px;
    color: white;
    position: absolute;
    left: 9%;
    bottom: 26px;
}

.blog_register_button {
    background: #BB423B;
    color: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
}

.blog_register_button1 {
    background: white;
    color: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
    color: #517A95
}

.blog_register_button1:focus {
    outline: none;
}

.blog-card {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    padding-top: 50px;

}

.blog-card1 {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    background-color: #E4B6B3;
}

.blogs_heading {
    font-size: 25px;
    color: #BB423B;
    margin-bottom: 0px;
}

.blog_horizontal_row_title {
    border: 0;
    border-top: 3px solid #BB423B;
    color: white;
    width: 60%;
    z-index: 1;
}

.blog-card2 {
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    background-color: #517A95;
    margin-bottom: 15px;


}

.blog-AuthorCard {
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    background-color: #EBB747;
    margin-bottom: 15px;


}

.blog_register_author_button {
    background: white;
    color: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
    color: #FAC85B
}

.blog-BookStore {
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    background-color: #BB423B;
    margin-bottom: 15px;


}

.blog_register_bookstore_button {
    background: white;
    color: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
    color: #BB423B;
}

.blog-workCollective {
    box-shadow: 0px 0px 10px 0px #b7b3;
    height: 298px;
    width: 100%;
    border-radius: 15px;
    background-color: #2A2A2A;
    margin-bottom: 15px;


}

.blog_register_WorkCollective_button {
    background: white;
    color: white;
    font-size: 15px;
    height: 39px;
    border-radius: 6px;
    width: 154px;
    border: none;
    color: #2A2A2A;
}

.discount-img {
    margin-top: 40px;
    margin-bottom: 15px;
}

.discount-img-heading {
    font-size: 18px;
    color: #505050
}

.discount-img-headingg {
    font-size: 21px;
    color: #505050;
}

.discount-img-heading1 {
    font-size: 18px;
    color: white
}

.discount_img_text {
    font-size: 12px;
}

.discount_img_text1 {
    font-size: 12px;
    color: white;
}

.blog_horizontal_row {
    border: 0;
    border-top: 2px solid white;
    color: white;
    position: absolute;
    left: 32px;
    width: 58%;
    z-index: 1;
    bottom: 31px;


}

.img-w-100 {
    width: 85%;
}

.img-h-82 {
    width: 100%;
    // height: 82%;
    height: 340px;

}

.img-h-62 {
    width: 100%;
    height: 62%;
}

.img-br-22 {
    border-radius: 22px;

}

//book section
.viewmore {
    font-size: 12px;
    color: #444444;
    text-align: right;
    margin-right: 30px;
    margin-left: 30px;
}

.viewmore:hover {
    cursor: pointer;
    color: black;
}

.book-heading {
    color: #BB423B;
    font-size: 25px;
}

.book-title {
    color: #444444;
    font-size: 16px;
}

.book-author {
    color: #444444;
    font-size: 14px;
}

.book-price-text {
    color: #2A9F1D;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
}

.book_rating {
    color: #444444;
    font-size: 12px;
}

//media query
@media (max-width: 1225px) {

    .dashboard_tabs_list {
        margin-left: 45px;
    }

    .dashboard_tabs {
        // margin-right: 60px;
    }
}

@media (max-width: 1142px) {

    .login_div {
        margin-left: 5px;
        margin-right: 5px;
    }


    .dashboard_header-text {

        margin-right: 10px;
    }
}

@media (max-width: 1040px) {

    .dashboard_tabs_list {
        margin-left: 50px;
    }

    .dashboard_tabs {
        // margin-right: 36px;
    }

    .dashboard_header-text {
        font-size: 12px;
    }

    .dashboard_header-sign-text {

        font-size: 12px;
    }

    .round-sign-div {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #BB423B;
        margin-left: 10px;
        margin-top: 0px;
    }

    .signin-count {
        color: white;
        font-size: 9px;
        margin-top: 4px;
        margin-left: 7px;
    }

    .searchicon {
        right: 5%;
    }

    .blog-text {
        font-size: 18px;

        left: 31px;
        bottom: 69px;
    }

    .blog_horizontal_row {

        bottom: 65px;
    }

    .blog-shadow {
        position: absolute;
        left: 22px;
        z-index: 0;
        top: 6px;
        height: 284px;
        width: 83%;
    }

    .banner-text {
        font-size: 28px;
    }
}

@media (max-width: 870px) {



    .dashboard_tabs_list {
        margin-left: 5px;
    }

    .dashboard_tabs {
        // margin-right: 36px;
        font-size: 13px;
    }

    .searchicon {
        right: 7%;
        top: 17px;

    }

    .logo_img {
        height: 61px;
        width: 138px;
    }

    .dashboard_header_input {
        font-size: 12px;
        margin-left: 0px;


    }

    .dashboard_header-text {
        margin-right: 5px;
    }

    .round-sign-div {

        margin-left: -1px;
        margin-top: -2px;


    }

    .dashboard_header-text {
        font-size: 10px;
    }

    .dashboard_header-sign-text {
        font-size: 10px;
    }

    .blog-text {
        font-size: 13px;
        left: 25px;
        bottom: 92px;
    }

    .blog_horizontal_row {
        bottom: 87px;
        left: 26px;
    }

    .blog-shadow {

        width: 79%;

    }
}

@media (max-width: 767px) {
    .dashboard_header-sign-text {
        font-size: 16px;
    }

    .dashboard_header-text {
        font-size: 15px;
    }

    .slideImg {
        object-fit: contain;
        height: 100%;
    }

    .authorCard {
        padding: 30px 20px 0px;
        height: 100%;
        margin-top: 20px;

        .text2 {
            font-size: 19px;
        }

        .text1 {
            font-size: 19px;
        }

        .text3 {
            font-size: 13px;
        }
    }

    .authorCard .btn {

        font-size: 13px;
        width: 90%;

    }

    .dashboard_header-text {
        margin-right: 15px;
    }

    .banner-text {
        font-size: 23px;
    }

    .login_div {
        margin-left: 172px;
        margin-right: 172px;
        margin-top: 20px;
    }

    .round-sign-div {
        height: 27px;
        width: 27px;
    }

    .signin-count {
        color: white;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 10px;

    }
}

@media (max-width: 740px) {

    .dashboard_tabs_list {
        margin-left: 5px;
    }

    .dashboard_tabs {
        // margin-right: 20px;
        font-size: 11px;
    }

    .logo_img {
        height: 84px;
        width: 179px;
    }

    .blog-text {
        font-size: 11px;
    }

    .blog-shadow {

        width: 74%;
        left: 20px;
    }

    .blog_horizontal_row {
        bottom: 92px;
        left: 26px;
    }



}

@media (max-width: 630px) {

    .dashboard_tabs_list {
        margin-left: 5px;
    }

    .dashboard_tabs {
        // margin-right: 5px;
        font-size: 11px;
    }

    // .dashboard_header_input {
    //     font-size: 14px;
    //     margin-left: 0px;
    //     margin-top: 10px


    // }
    .logo_img {
        height: 84px;
        width: 179px;
    }

    .login_div {
        margin-left: 100px;
        margin-right: 100px;
    }

    .blog-text {
        font-size: 19px;
        bottom: 36px;
        left: 28%;
        z-index: 9;
    }

    .blog_horizontal_row {
        bottom: 103px;
        left: 26px;
    }

    .banner-logo-icon {
        z-index: 1;
        position: absolute;
        top: 11%;
        left: 10%;
        height: 34px;
    }

    .banner-text {
        font-size: 14px;
        margin-top: 3px;
    }

    .banner_horizontal_row {
        border-top: 2px solid white;

    }

    .banner-small-text {
        font-size: 11px;
        color: white;
        z-index: 1;
        position: absolute;
        top: 56%;
        left: 9%;
    }

    .banner-button {

        left: 8%;
    }
}

@media (max-width: 576px) {

    .blog-shadow {
        width: 83%;
    }

    .dashboard_tabs_list {
        margin-left: 5px;
    }

    .dashboard_tabs {
        margin-right: 5px;
        font-size: 9px;
    }

    .blog_horizontal_row {
        bottom: 59px;
        left: 34px;
    }

}