.visible{
    z-index: 1;

}
.EasyPaisaError{
    text-align: center;
    color: #d11313;
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
}
.EasyPaisaSuccess{
    text-align: center;
    color: rgb(12, 158, 12);
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
}
.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 220px; 
    // height: 1.5em; 
    white-space: nowrap;
  }
  .cut-text:hover{
      cursor: pointer;
  }
  .cut-text1 { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 100px; 
    height: 1.2em; 
    white-space: nowrap;
  }
  .tooltipq {
    position: relative;
    display: inline-block;
  }
  
  .tooltipq .tooltipqtext {
    visibility: hidden;
    width: 160px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltipq:hover .tooltipqtext {
    visibility: visible;
  }
.Payment-methodImg{
    margin-left: 6px    ;
        margin-top: 12px    ;
        height: 30px;
}
.paymentDropdown{
    height: 95px    ;
        background: white;
        padding: 30px;
        border-radius: 15px;
}
.paymentCheckoutBtn{
    height: 63px;
    background: white;
    border-radius: 15px;
    background: #BB423B;
    font-size: 19px;
    color: white;
    font-family: 'Poppins';
    font-weight: 600;
    margin-top: 45px !important;
    border: none;
}
.paymentCheckoutBtn:hover{
    background-color: #f8ebba;
    color: #B5322A;
}
.paymentCheckoutBtn:focus{
    outline: none;
    border: 2px solid #B5322A;}
.proceedContainer{
    background: #F6F6F6;
    padding: 30px;
    border-radius: 14px;
}
.internalproceedContainer{
    background: #fff;
    padding: 15px;
    border-radius: 14px;
}
.internalproceedContainerHeading{
    font-size: 15px;
}
.internalproceedContainerText{
font-size: 14px;
color: #7A7A7A;
}
.internalproceedContainerText1{
    font-size: 20px;
}
.internalproceedLine{
    border-bottom: 1px  solid #d1cccc;
        width: 94%;
        margin: auto;
}

.checkout_heading{
    font-size: 33px;
    color: #B9B9B9;

}
.checkout_paymentimg{
    width: 129px;
    height: 26px;
    object-fit: cover;
    position: absolute;
    top: 2px;
    left: 65px;

}
.checkout_paymentimgPaypal{

width: 159px;
height: 40px;
object-fit: cover;
position: absolute;
top: -8px;
left: 74px;
}

.checkout_paymentimgForee {
    width: 498px;
    height: 40px;
    object-fit: cover;
    position: absolute;
    top: -8px;
    left: 52px;
}
.checkout_paymentimgJazzCash {
    width: 215px;
    height: 84px;
    object-fit: cover;
    position: absolute;
    top: -27px;
    left: 52px;
}
.checkout_paymentimgHBL {
    width: 103px;
    height: 64px;
    object-fit: cover;
    position: absolute;
    top: -17px;
    left: 52px;
}
.checkout_paymentimgJazzCash1 {
    width: 273px;
    height: 155px;
    object-fit: cover;}
.payment_methodImgHeight{
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
}
//confrimpaymentPAge
.confirmPaymentInput{
    border: 1px solid #6b99b9;
    height: 44px;
    border-radius: 7px;
    font-size: 17px;
    letter-spacing: 5px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #707070;
}
.confirmPaymentInput{
    border: 2px solid #BB423B;
    outline: none;
}
.infoText{
    font-size: 13px;
    color: #606060;
}
.paynowbtn{
    height: 44px;
    background: #BB423B;
    color: white;
    border-radius: 7px;
    font-size: 18px;
    border: none;
}
.paynowbtn:focus{
    outline: none;
    opacity: 0.7;
}
.orderDetail{
    background-color: #F6F6F6;
    border-radius: 3px;
    padding: 25px;
}
.orderDetailhead{
    color: #606060;
    font-size: 25px;
}
.orderDetailInnerCard{
    background: white;
    margin: 20px;
    padding: 20px;

}
.orderDetailInnerCardTitle{
    font-size: 15px;
    color: #393939;
    margin-bottom: 10px;

    
}
.orderDetailInnerCardText{
    color: #7A7A7A ;
    font-size: 13px;
    margin-bottom: 0px;
}
.orderDetailInnerCardBText{
    color: #393939 ;
    font-size: 20px;
    margin-bottom: 0px;
}
.orderDetailInnerCardBText2{
    color: #BB423B ;
    font-size: 20px;
    margin-bottom: 0px;
}
//confirmPayment Page End

//confrimorder
.confirmorderReadbtn{
    height: 38px;
    width: 114px;
    border: none;
    background: #BB423B;
    color: white;
    font-family: 'Poppins';
    font-weight: 500;
    border-radius: 7px;
}
.confirmorderReadbtn:focus{
    opacity: 0.8;
}
.checkout_container{
    .tableWidth{
        width: 100%;
        margin-bottom: 1rem;
        /* border: 1px solid; */
        color: #212529;
        margin: auto;
    }
    .table td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: none;
    }
    .table>thead:first-child>tr:first-child>th {
        border-top: 0;
        border-bottom: 0;
        color: #A4ACAE !important;
        font-size: 17px !important;
        font-family: poppins;
        font-weight: 500;;
    }
    .table_header{
        color: #A4ACAE !important;
        font-size: 17px !important;
    }
   td{
       text-align: center;
       font-family: poppins;
       font-weight: 400;
       font-size: 15px;

   }
   .Remove-text{
       font-size: 12px;
       color: blue;
   }
   .Remove-text:hover{
       opacity: 0.8;
       cursor: pointer;
   }
   th{
       text-align: center;
   }
   .new {
    padding: 50px;
  }
  
  .form-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #33658A;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 0px;
    height: 0px;
    border: 9px solid #0079bf;
    border-radius: 5px;
   
  }
}
//radio-btn
.payment_method{
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 19px;
    color: #BB423B;}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #BB423B;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #BB423B;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
}
.checkOutCard{
        border: #707070;
        box-shadow: 0px 0px 1px 0px #3c3838;
        margin-left: auto;
        margin-right: auto;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-right: 20px;
        padding-left: 20px;
        border-radius: 15px;
        position: absolute;
        top: 15px;
        right: 1px;
        height: 170px;
        width: 493px;
    }
      
    

.checkOutbtn{
    height: 50px;
    background-color: #BB423B;
    font-size: 24px;
    border: none;
    color: white;
    border-radius: 10px;
}
.checkOutbtn:focus{
    outline: none;
    opacity: 0.8;
}
.checkOutCardAmountText{
    font-size: 28px;
    color: #BB423B;
    line-height: 0.9;



}
.checkOutCardAmountSmText{
font-size: 11px;
}
.checkOutCardAmountLGText{
    font-size: 34px;
    color: #45AB3A;
    }

    @media (max-width: 840px) {
        .checkOutCard{
            width: 300px;
            position: relative;
        }
        .checkOutCardAmountText {
            font-size: 16px;
        }
        .checkOutCardAmountLGText {
            font-size: 24px;
            color: #45AB3A;
        }
        .checkout_heading {
            font-size: 24px;
        }
        .payment_method [type="radio"]:checked + label, .payment_method [type="radio"]:not(:checked) + label {
        
            font-size: 12px;
            color: #BB423B;
        }
      }  
      @media (max-width: 740px) {

      .cut-text {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 130px;
        height: 1.2em   ;
        white-space: nowrap;
    }

}
      .GoToBookShelfBtn{
        background-color: #bb423b;
        color: #fff;
        border: none;
        font-size: 16px;
        height: 44px;
        border-radius: 5px;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }


      @media (max-width: 576px) {
        .cut-text {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 220px;
            height: 1.2em   ;
            white-space: nowrap;
        }
      }